@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    /* Flex */
    .dec-flex {
        @apply flex flex-col md:flex-row;
    }

    /* Margin/Padding */
    .dec-px {
        @apply px-8 md:px-12 lg:px-24;
    }
    .dec-mx {
        @apply mx-8 md:mx-12 lg:mx-24;
    }
    .dec-py {
        @apply py-8 md:py-12 lg:py-24;
    }
    .dec-my {
        @apply my-8 md:my-12 lg:my-24;
    }



    /* Card */
    .dec-card {
        @apply px-6 pt-6 pb-14 my-8 md:my-auto;
    }




    /* Flex */
    .dec-flex {
        @apply flex flex-col md:flex-row;
    }





    /* Buttons */
    .dec-btn {
        @apply bg-blue-400 py-2 px-6 rounded-lg inline-block my-4 text-white hover:bg-transparent hover:text-blue-400 border hover:border-blue-400 focus:bg-transparent focus:text-blue-400 border focus:border-blue-400;
    }

    .dec-btn-outline {
        @apply px-8 py-2 rounded border border-blue-400 text-blue-400 hover:bg-blue-400 hover:text-white focus:bg-blue-400 focus:text-white;
    }



    /* combination */
    .dec-section {
        @apply dec-flex dec-px py-8;
    }

    .dec-section-inner {
        @apply px-2 md:px-4 lg:px-8 py-2 md:py-4;
    }
}